import {PATHS} from "./utils/Paths.links";
import ProfilePage from "../pages/ProfilePage";
import ShopPage from "../pages/ShopPage";
import {ComponentType} from "react";
import DepositPage from "../pages/DepositPage/DepositPage";
import AgreementPage from "../pages/AgreementPage/AgreementPage";
import PrivacyPage from "../pages/ PrivacyPage/ PrivacyPage";
import TicketPage from "../pages/TicketPage/TicketPage";

export interface IRoute {
    path: string;
    component: ComponentType;
    exact?: boolean;
    withoutAuth?: boolean,
    childRoutes?: IRoute[]
}

export const Routes: IRoute[] = [
    { path: PATHS.SHOP, exact: true, component: ShopPage, withoutAuth: true },
    { path: PATHS.PROFILE, exact: false, component: ProfilePage, withoutAuth: false },
    { path: PATHS.DEPOSIT, exact: false, component: DepositPage, withoutAuth: false },
    { path: PATHS.AGREEMENT, exact: false, component: AgreementPage, withoutAuth: true },
    { path: PATHS.PRIVACY, exact: false, component: PrivacyPage, withoutAuth: true },
    { path: PATHS.TICKETS, exact: false, component: TicketPage, withoutAuth: false },
];

