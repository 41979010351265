import Popup from "../../../../../shared/Popup";
import buyMe from "../../../../../assets/buymeacoffee.png";
import buyMeCoffee from "../../../../../assets/info-payment-buyme.png"
import {useState} from "react";
import CustomIcon from "../../../../../shared/SvgIcons/CopyIcon";
import {copyText} from "../../../../../shared/functions/clickCopyText";
import Toaster, {ToasterProps} from "../../../../../shared/Toaster/Toaster";
import {getCurrentDateInSeconds} from "../../../../../shared/functions/getRandomId";

const PopupNotFullyIntegration = (props) => {
    const {activePopup, setActivePopup, user, amountDeposit} = props;
    const [activeExamplePopup, setActiveExamplePopup] = useState(false);
    const openInNewTab = () => {
        window.open("https://buymeacoffee.com/alcatrazdayz", '_blank', 'noopener,noreferrer');
    };

    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);
    const copyTextFunc = async (text: string) => {
        try {
            await copyText(text) // Ждём завершения операции копирования
            setToastProps({
                message: "Данные добавлены в буфер обмена",
                type: "success",
                id: getCurrentDateInSeconds()
            })
        } catch (error) {
            setToastProps({
                message: "Ошибка при копировании данных в буфер обмена",
                type: "error",
                id: getCurrentDateInSeconds()
            })
        }
    }

    return (
        <>
            <Popup title={"Информация"} activePopup={activePopup} setActivePopup={setActivePopup} >
                <div className="notFullyMethodContainer">
                    <img className="logo-method" src={buyMe} alt="logo"/>
                    <p>
                        Данный сервис не поддерживает полную интеграцию, по этому вам нужно скопировать некоторые данные и перейти по ссылке для оплаты.
                    </p>
                    <div className="data-for-payment d-flex flex-direction-column">
                        <div className="data-title" style={{marginBottom: "6px"}}>
                            <div onClick={() => copyTextFunc(user.steamId)} className="data-title-content">
                                Ваш SteamId - <strong style={{fontSize: "16px", paddingLeft: "3px"}}>{ user.steamId }</strong>
                            </div>
                            <button onClick={() => copyTextFunc(user.steamId)} className="copy-button">
                                <CustomIcon />
                            </button>
                        </div>
                        <div className="data-title">
                            { amountDeposit && <>
                                <div className="data-title-content">
                                    Сумма платежа - <strong style={{fontSize: "16px", paddingLeft: "3px"}}>{ amountDeposit.toFixed(2) } USD</strong>
                                </div>
                                <button onClick={() => copyTextFunc( amountDeposit?.toFixed(2))} className="copy-button">
                                    <CustomIcon />
                                </button>
                            </>
                            }

                        </div>
                    </div>
                    <div className="more-details">
                        <p>
                            Правильно оформленый платеж - автоматическое зачисление баланса!
                        </p>
                        <div className="more-details-method-block">
                            <strong>Как правильно оплатить? </strong> <button className="more-details-method" onClick={() => setActiveExamplePopup(true)}>CLICK</button>
                        </div>
                        {/*<div className="more-details-method-block">*/}
                        {/*    <strong>Возникли вопросы при оплате? </strong>*/}
                        {/*    <button className="more-details-method">CLICK</button>*/}
                        {/*</div>*/}
                    </div>
                    <button onClick={openInNewTab} className="button-buy">
                        <div className="d-flex just-content-center ai-center">
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="var(--white-color)">
                                <path d="M19.5 18.19c-2.481 0-4.5-1.922-4.5-4.285 0-2.363 2.019-4.286 4.5-4.286H22V7.714c0-1.05-.897-1.904-2-1.904h-1V3.905C19 2.855 18.103 2 17 2H3C1.517 2 .291 3.032.051 4.38.029 4.458 0 4.534 0 4.619v14.524C0 20.718 1.346 22 3 22h17c1.103 0 2-.854 2-1.905v-1.904h-2.5zM2 4.857c0-.525.448-.952 1-.952h14V5.81H3c-.552 0-1-.428-1-.953z"></path>
                                <path d="M23.25 11.048H19.5c-1.654 0-3 1.281-3 2.857 0 1.575 1.346 2.857 3 2.857h3.75c.414 0 .75-.32.75-.714v-4.286c0-.394-.336-.714-.75-.714zm-3.75 3.81c-.552 0-1-.428-1-.953 0-.526.448-.953 1-.953s1 .427 1 .953-.448.952-1 .952z"></path>
                            </svg>
                            <p style={{marginLeft: "6px"}}>
                                Перейти к оплате
                            </p>
                        </div>
                    </button>
                </div>
            </Popup>
            <Popup title={"Пример заполнения оплаты"} activePopup={activeExamplePopup} setActivePopup={setActiveExamplePopup}>
                <img src={buyMeCoffee} alt="info"/>
            </Popup>
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </>

    );
};

export default PopupNotFullyIntegration;