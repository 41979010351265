import SkinMethod, {ISkinMethod} from "./components/SkinMethod";
import cs2img from "../../../../assets/skinify_csgo.webp";
import tf2img from "../../../../assets/skinify_tft.webp";
import gameRust from "../../../../assets/skinify_rust.webp";
import gameDota from "../../../../assets/skinify_dota.webp";
import "./SkinMethods.scss";
import fkwalletimg from "../../../../assets/fkwallet.svg";


const skinsMethods = [
    {
        name: "CS2",
        style: "radial-gradient(94.18% 100% at 50% 100%, rgba(73, 135, 255, .5) 0, rgba(51, 53, 76, .5) 100%)",
        img: cs2img,
        createUrl: "/api/payment-method/create-freekassa",
        preferMethod: "27",
        min: 115,
        currency: "RUB",
        buttonsData: [
            { sum: 115 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
    {
        name: "DOTA",
        style: "radial-gradient(178.01% 126.67% at 50% 100%, rgba(255, 106, 115, .5) 0, rgba(24, 24, 36, .5) 100%)",
        img: gameDota,
        createUrl: "/api/payment-method/create-freekassa",
        preferMethod: "27",
        min: 115,
        currency: "RUB",
        buttonsData: [
            { sum: 115 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
    {
        name: "RUST",
        style: "radial-gradient(178.01% 126.67% at 50% 100%, rgba(255, 177, 105, .5) 0, rgba(24, 24, 36, .5) 100%)",
        img: gameRust,
        createUrl: "/api/payment-method/create-freekassa",
        preferMethod: "27",
        min: 115,
        currency: "RUB",
        buttonsData: [
            { sum: 115 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
    {
        name: "TF2",
        style: "radial-gradient(178.01% 126.67% at 50% 100%, rgba(255, 143, 95, .5) 0, rgba(24, 24, 36, .5) 100%)",
        img: tf2img,
        createUrl: "/api/payment-method/create-freekassa",
        preferMethod: "27",
        min: 115,
        currency: "RUB",
        buttonsData: [
            { sum: 115 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    }
]


const SkinMethods = ({setMethodPayment, setActivePopup, setActivePopupNotFully}) => {
    const activeMethod = (method) => {
        setActivePopup(true)
        setMethodPayment(method)
    }

    return (
        <>
            <h4 className="title-skins title-method">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--deposit-title-method)">
                    <path
                        d="M19 3H5a5.006 5.006 0 00-5 5h24a5.006 5.006 0 00-5-5zM0 16a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5v-6H0v6zm7-.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                    ></path>
                </svg>
                <p>Оплата скинами</p>
            </h4>
            <div className="d-flex flex-direction-column ">
                <div className="skin-methods">
                    { skinsMethods.map((method, idx) => {
                        return (
                            <SkinMethod
                                key={idx}
                                activeMethod={activeMethod}
                                method={method}
                            />
                        )
                    })
                    }
                </div>
            </div>
        </>
    );
};

export default SkinMethods;