import spbimg from "../../../../assets/spb.svg";
import cardimg from "../../../../assets/visa-ms.svg";
import cardimg2 from "../../../../assets/paypalych-logo.svg";
import cardimg3 from "../../../../assets/SberPay.png";
import cardimg4 from "../../../../assets/usdt.png";
import fkwalletimg from "../../../../assets/fkwallet.svg";
import iomoneyimg from "../../../../assets/iomoney.svg";
import "./DefaultMethods.scss";
import PopupNotFullyIntegration from "./PopupNotFullyIntegration/PopupNotFullyIntegration";
import {useEffect, useState} from "react";
import axios from "axios";
import {PATHS_API} from "../../../../../enum";

const defaultMethods: IDefaultMethod[] = [
    {
        paymentSystem: "Paypalych",
        name: "QR",
        createUrl: "/api/payment-method/create-paypalych",
        preferMethod: "SBP",
        img: spbimg,
        min: 100,
        currency: "RUB",
        buttonsData: [
            { sum: 100 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
    // {
    //     paymentSystem: "Antilopay",
    //     name: "QR #2",
    //     createUrl: "/api/payment-method/create-antilopay",
    //     preferMethod: "SBP",
    //     min: 100,
    //     currency: "RUB",
    //     img: spbimg,
    //     buttonsData: [
    //         { sum: 100 },
    //         { sum: 500 },
    //         { sum: 1000 },
    //         { sum: 3000 },
    //     ],
    //     iconCurrency: "₽"
    // },
    // {
    //     paymentSystem: "Paypalych",
    //     name: "CARD",
    //     createUrl: "/api/payment-method/create-paypalych",
    //     preferMethod: "BANK_CARD",
    //     img: cardimg2,
    //     min: 100,
    //     currency: "RUB",
    //     buttonsData: [
    //         { sum: 100 },
    //         { sum: 500 },
    //         { sum: 1000 },
    //         { sum: 3000 },
    //     ],
    //     iconCurrency: "₽"
    // },
    {
        paymentSystem: "Antilopay",
        name: "CARD",
        createUrl: "/api/payment-method/create-antilopay",
        preferMethod: "CARD_RU",
        img: cardimg2,
        min: 100,
        currency: "RUB",
        buttonsData: [
            { sum: 100 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
    {
        paymentSystem: "BuyMeACoffee",
        name: "CARD EU",
        createUrl: "none",
        preferMethod: "",
        img: cardimg,
        min: 1,
        currency: "USD",
        buttonsData: [
            { sum: 1 },
            { sum: 10 },
            { sum: 15 },
            { sum: 35 },
        ],
        iconCurrency: "$"

    },
    {
        paymentSystem: "P2P Ukraine",
        name: "CARD UA",
        createUrl: "/api/payment-method/create-betatransfer",
        preferMethod: "Card5",
        img: cardimg,
        min: 300,
        currency: "UAH",
        buttonsData: [
            { sum: 300 },
            { sum: 800 },
            { sum: 1000 },
            { sum: 2000 },
        ],
        iconCurrency: "₴"
    },
    {
        paymentSystem: "Paypalych",
        name: "CARD KZ",
        createUrl: "/api/payment-method/create-paypalych",
        preferMethod: "BANK_CARD",
        img: cardimg,
        min: 250,
        currency: "RUB",
        buttonsData: [
            { sum: 250 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
    {
        paymentSystem: "Antilopay",
        name: "SberPay",
        createUrl: "/api/payment-method/create-antilopay",
        preferMethod: "SBER_PAY",
        img: cardimg3,
        min: 100,
        currency: "RUB",
        buttonsData: [
            { sum: 100 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
    {
        paymentSystem: "Crypto",
        name: "Crypto",
        createUrl: "/api/payment-method/create-betatransfer",
        preferMethod: "Crypto",
        img: cardimg4,
        min: 5,
        currency: "USD",
        buttonsData: [
            { sum: 5 },
            { sum: 10 },
            { sum: 20 },
            { sum: 30 },
        ],
        iconCurrency: "$"
    },
    // {
    //     paymentSystem: "FreeKassa",
    //     name: "Crypto #2",
    //     createUrl: "/api/payment-method/create-freekassa",
    //     preferMethod: "any",
    //     img: cardimg4,
    //     min: 300,
    //     currency: "RUB",
    //     buttonsData: [
    //         { sum: 300 },
    //         { sum: 500 },
    //         { sum: 1000 },
    //         { sum: 3000 },
    //     ],
    //     iconCurrency: "₽"
    // },
    {
        paymentSystem: "FreeKassa",
        name: "FK Wallet",
        createUrl: "/api/payment-method/create-freekassa",
        preferMethod: "1",
        img: fkwalletimg,
        min: 100,
        currency: "RUB",
        buttonsData: [
            { sum: 100 },
            { sum: 500 },
            { sum: 1000 },
            { sum: 3000 },
        ],
        iconCurrency: "₽"
    },
]

export interface IDefaultMethod {
    name: string,
    img: any,
    createUrl: string,
    preferMethod: string,
    paymentSystem: string,
    min: number,
    currency: string,
}

const DefaultMethods = ({setMethodPayment, setActivePopup, setActivePopupNotFully}) => {
    const [userLocation, setUserLocation] = useState(null)
    const activeMethod = (method) => {
        setActivePopup(true)
        setMethodPayment(method)
    }


    useEffect(() => {
        axios.get( PATHS_API.LOCATION, {
            withCredentials: true,
        })
            .then(({data}) => {
                setUserLocation(data.country);
            })
            .catch((error) => {
                console.error("Ошибка при загрузке", error);
            })
    },[])

    const filteredMethods = defaultMethods.filter((method) => {
        // Убираем объекты "BuyMeACoffee" и "P2P Ukraine", если локация "RU" или "BY"
        if (userLocation === "RU" || userLocation === "BY" || userLocation == null) {
            return method.paymentSystem !== "BuyMeACoffee" && method.paymentSystem !== "P2P Ukraine";
        }
        return true; // Остальные объекты остаются
    });

    return (
        <div style={{marginBottom: "20px"}}>
            <h4 className="title-method">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--deposit-title-method)">
                    <path
                        d="M19 3H5a5.006 5.006 0 00-5 5h24a5.006 5.006 0 00-5-5zM0 16a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5v-6H0v6zm7-.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                        ></path>
                </svg>
                <p>Карты и крипта</p>
            </h4>
            <div style={{marginBottom: "20px"}} className="methods">
                {
                    filteredMethods.map((method,idx) => {
                        return (
                            <div key={idx} onClick={() => activeMethod(method)} className="default-method-block d-flex">
                                <div className="default-method-name d-flex just-content-space-between">
                                    <p>{ method.name }</p>
                                </div>
                                <img src={ method.img } alt={ method.name } />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default DefaultMethods;