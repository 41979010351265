import './App.scss'
import {ThemeProvider} from "./providers/ThemeProvider";
import AppRouter from "./routers/AppRouter";
import Navbar from "./pages/ShopPage/components/Navbar/Navbar";
import ScrollToTop from "./shared/ScrollToTop/ScrollToTop";
import Snowfall from 'react-snowfall'

function App() {
  return (
      <ThemeProvider>
          <ScrollToTop />
          <Navbar />
          <AppRouter />
          {/*<Snowfall style={{*/}
          {/*    zIndex: -1,*/}
          {/*    position: 'fixed',*/}
          {/*    width: '100vw',*/}
          {/*    height: '100vh',*/}
          {/*}} snowflakeCount={100} speed={[1,2]} color={"#c0c0c0"} opacity={[0.2, 0.8]} />*/}
      </ThemeProvider>
  )
}

export default App
