import {useEffect, useState} from 'react';
import {dataPurchaseType} from "../../data";
import Popup from "../../../../../../shared/Popup";
import DropDownPurchase from "../../../../../../shared/DropDowns/DropDownPurchase";
import axios from "axios";
import 'rsuite/useToaster/styles/index.css';
import 'rsuite/Message/styles/index.css';
import {useUserStore} from "../../../../../../../modules/shop/user/store";
import Toaster from "../../../../../../shared/Toaster";
import CardProductPersonal from "../CardProductPersonal/CardProductPersonal";
import CardProduct from "../CardProduct/CardProduct";
import {PATHS_API} from "../../../../../../../enum";
import {ToasterProps} from "../../../../../../shared/Toaster/Toaster";
import {getCurrentDateInSeconds} from "../../../../../../shared/functions/getRandomId";

const Product = ({ data, servers, randomProducts, personal }) => {
    const [activePopup, setActivePopup] = useState(false)
    const [confirmActivePopup, setConfirmActivePopup] = useState(false)
    const [quantity, setQuantity] = useState(data.count)
    const user = useUserStore(store => store.user)
    const fetchUser = useUserStore(store => store.fetchUser)


    const [activeServer, setActiveServer] = useState({_id: 0, name: "Сервер"});
    const [activeTypePurchase, setActiveTypePurchase] = useState(dataPurchaseType[0])

    // Получить список серверов и отфильтровать лишние
    const filterServers = servers.filter((server) => {
        return !data.servers.includes(server._id);
    });

    useEffect(() => {
        setQuantity(data.count);
    }, [data.count]);

    //Количество товара за раз
    const funcMinus = () => setQuantity(quantity > data.count ? quantity - data.count : quantity)
    const funcPlus = () => setQuantity(quantity + data.count)


    //Переход к подтверждению покупки
    const funcConfirm = () => {
        if(!activeServer._id) {
            setActivePopup(false)
            return setToastProps({ message: "Вы не выбрали сервер покупки!", type: "warning", id: getCurrentDateInSeconds() });
        }

        if(activeServer._id) {
            setActivePopup(false)
            setConfirmActivePopup(true)
        }
    }

    //Покупка товара
    const [friendSteamId, setFriendSteamId] = useState("")
    const ownerProductId = activeTypePurchase.id === "self" ? user.steamId : friendSteamId
    const [isLoading, setIsLoading] = useState(false);

    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);

    const handleBuyProduct = async () => {
        // Проверка наличия SteamId друга
        if (isLoading) return; // предотвращает повторные клики
        setIsLoading(true);

        if (ownerProductId !== user.steamId && friendSteamId === "") {
            setToastProps({ message: "Вы не указали steamId своего друга!", type: "error", id: getCurrentDateInSeconds() });
            setConfirmActivePopup(false);
            return; // Прерываем выполнение
        }

        try {
            // Отправка запроса на сервер для покупки
            const response = await axios.post(`${PATHS_API.PURCHASES}/${ownerProductId}`, {
                productId: data._id,
                server: activeServer,
                quantity: quantity / data.count,
            }, {
                withCredentials: true,
            });

            // Если покупка успешна, показываем сообщение и обновляем данные пользователя
            setToastProps({ message: response.data.message, type: response.data.statusCode === 201 ? "success" : "error", id: getCurrentDateInSeconds() });
            fetchUser();
        } catch (error) {
            // В случае ошибки показываем сообщение об ошибке
            const errorMessage = error.response?.data?.message || "Произошла ошибка";
            setToastProps({ message: errorMessage, type: "error", id: getCurrentDateInSeconds()});
        } finally {
            // Закрытие попапа после выполнения запроса
            setConfirmActivePopup(false);
            setIsLoading(false);
        }
    };


    return (
        <>
            <div onClick={() => setActivePopup(true)} className={personal ? "product-personal" : "product"}>
                { personal
                    ? <CardProductPersonal data={data} />
                    : <CardProduct data={data} />
                }
            </div>
            <Popup title={data.name} activePopup={activePopup} setActivePopup={setActivePopup}>
                <div className="wrapper-desc">
                    <div className="purchase-info">
                        <div className="product-desc-img-wrapper">
                            <img className="product-desc-img" src={data.imageUrl} alt="product-img"/>
                            { data.discount && <span className="discount-desc-info">-{ data.discount * 100 }%</span> }
                        </div>
                        <p className="desc-product">
                            {data.description}
                        </p>
                        <div className="quantity-block">
                            <div className="quantity-btn-block">
                                <button
                                    className="quantity-btn"
                                    onClick={funcMinus}
                                >
                                    -
                                </button>
                                { quantity }
                                <button
                                    className="quantity-btn"
                                    onClick={funcPlus}
                                >
                                    +
                                </button>
                            </div>
                            <div className="price-desc">
                                RUB <span>{data.price / data.count * quantity}</span>
                            </div>
                        </div>
                        <div className="details-purchase">
                            <DropDownPurchase
                                data={filterServers}
                                activeOption={activeServer}
                                setActiveOption={setActiveServer}
                                width={"135px"}
                            />
                            <div className="wrapper-type-purchase">
                                <DropDownPurchase
                                    data={dataPurchaseType}
                                    activeOption={activeTypePurchase}
                                    setActiveOption={setActiveTypePurchase}
                                    width={"205px"}
                                />
                                { activeTypePurchase.id !== "self" && <input style={{outline: "none", color: "var(--main-text-color)"}} value={friendSteamId} onChange={(e) => {setFriendSteamId(e.target.value)}} placeholder={"SteamId друга"} type="number"/> }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    user.steamId
                        ? <button onClick={funcConfirm} className="button-buy">Купить</button>
                        : <button
                            onClick={() => window.location.href = PATHS_API.LOGIN}
                            className="button-buy"
                        >
                            Ввойти через Steam
                        </button>
                }
            </Popup>
            {/*<Popup title={data.name} activePopup={activePopup} setActivePopup={setActivePopup}>*/}
            {/*    <div className="random-product-wrap">*/}
            {/*        <div style={{ flexWrap: "wrap"}} className="d-flex">*/}
            {/*            { randomProducts.map((product, idx) => {*/}
            {/*                return (*/}
            {/*                    <>*/}
            {/*                        <div className="random-product" key={idx}>*/}
            {/*                            <img className="random-product-img" src={product.imageUrl} alt={product.name}/>*/}
            {/*                        </div>*/}
            {/*                    </>*/}
            {/*                )})*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div>*/}

            {/*            {*/}
            {/*                user.steamId*/}
            {/*                    ? <div>*/}
            {/*                        <div style={{width: "100%"}} className="d-flex">*/}
            {/*                            <DropDownPurchase*/}
            {/*                                data={filterServers}*/}
            {/*                                activeOption={activeServer}*/}
            {/*                                setActiveOption={setActiveServer}*/}
            {/*                                width={"100%"}*/}
            {/*                            />*/}
            {/*                            <div className="price-desc">*/}
            {/*                                RUB <span>{data.price / data.count * quantity}</span>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <button onClick={funcConfirm} className="button-buy">Купить</button>*/}
            {/*                    </div>*/}
            {/*                    : <button*/}
            {/*                        onClick={() => window.location.href = PATHS_API.LOGIN}*/}
            {/*                        className="button-buy"*/}
            {/*                    >*/}
            {/*                        Ввойти через Steam*/}
            {/*                    </button>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Popup>*/}
            <Popup title={"🤔"} activePopup={confirmActivePopup} setActivePopup={setConfirmActivePopup} positionCenter>
                <div className="modal-confirm">
                    Вы точно хотите приобрести
                    <strong> {data.name} (x{quantity})</strong>
                    <br/> на сервер
                    <strong> { activeServer.name }</strong> ?
                </div>
                <div className="info-pickup">
                    После покупки товар можно забрать на клавишу <strong>"u"</strong>
                </div>
                <div className="modal-confirm-wrapper-btn">
                    <button disabled={isLoading} onClick={handleBuyProduct} className="button-buy">{ isLoading ? "Ожидание ответа..." : "Да" }</button>
                    <button onClick={() => setConfirmActivePopup(false)} className="button-buy button-secondary">Отмена</button>
                </div>
            </Popup>
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </>

    );
};

export default Product;