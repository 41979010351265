import {CATEGORY_NAME} from "../../../../../enum";

export const filterCategoryResult = (activeCategory, dataProducts) => {
    return dataProducts.filter((product) => {
        // Исключаем товары с type: "random"
        if (product.type === "random") return false;

        if (activeCategory.name === CATEGORY_NAME.ALL) {
            return true;
        } else {
            const filterProducts: string[] = product.categories || [];
            return filterProducts.includes(activeCategory._id);
        }
    });
}