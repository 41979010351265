import "./Navbar.scss";
import logo2 from "/src/app/assets/logo-mini.png"
import logo from "/src/app/assets/logo.png";
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useUserStore} from "../../../../../modules/shop/user/store";
import {PATHS, PATHS_API} from "../../../../../enum";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import {CHILD_PROFILE_PATHS} from "../../../ProfilePage/components/Sidebar/Sidebar";
import DepositWallet from "../../../../shared/UIComponents/DepositWallet/DepositWallet";
import NotificationBell from "./components/NotificationBell/NotificationBell";
import BurgerButton from "./components/BurgerButton/BurgerButton";

const Navbar = () => {
    const user = useUserStore(state => state.user)
    const fetchUser = useUserStore(state => state.fetchUser)
    const location = useLocation();

    useEffect(() => {
        fetchUser()
    }, [])

    const ff = () => localStorage.setItem('isAuth', 'true')

    return (
        <div className="wrapper-fake">
            <div className="wrapper">
                <nav className="navbar">
                    <Link className="navbar-brand" to={PATHS.SHOP}>
                        <img className="navbar-logo" src={logo} alt="logo"/>
                        <img className="navbar-logo-mini" src={logo2} alt="logo"/>
                    </Link>
                    <div className="wrapper-theme-switcher">
                        {/*<Link className="support-btn" to="/tickets">*/}
                        {/*    <svg width="18" height="18" viewBox="0 0 24 24" fill="none">*/}
                        {/*        <path fill-rule="evenodd" clip-rule="evenodd"*/}
                        {/*              d="M15.852 2.035c-.304 0-.6-.091-.846-.263L12.844.263a1.484 1.484 0 00-1.688 0L8.994 1.772a1.476 1.476 0 01-.846.263L5.47 2.03c-.622 0-1.174.383-1.365.949l-.822 2.437a1.374 1.374 0 01-.523.688L.592 7.606a1.337 1.337 0 00-.521 1.535l.832 2.433c.094.277.094.575 0 .852L.07 14.859a1.337 1.337 0 00.521 1.536l2.169 1.5c.246.171.43.412.523.69l.822 2.436c.191.566.743.95 1.365.949l2.677-.005c.304 0 .6.091.846.263l2.162 1.509c.503.35 1.185.35 1.688 0l2.162-1.509c.246-.172.542-.264.846-.263l2.677.005c.622 0 1.174-.383 1.365-.949l.822-2.437c.094-.277.277-.518.523-.689l2.169-1.5c.504-.35.714-.97.521-1.536l-.832-2.433a1.313 1.313 0 010-.852l.832-2.433a1.337 1.337 0 00-.521-1.535l-2.169-1.502a1.374 1.374 0 01-.523-.688l-.822-2.437c-.191-.566-.743-.95-1.365-.949l-2.677.005zM12 20.217c4.744 0 8.59-3.679 8.59-8.217S16.743 3.783 12 3.783 3.41 7.462 3.41 12s3.846 8.217 8.59 8.217z"*/}
                        {/*              fill="#a9b7cc"></path>*/}
                        {/*        <path*/}
                        {/*            d="M11.386 8.12a.667.667 0 011.228 0l.865 2.047a.66.66 0 00.354.354l2.047.865a.667.667 0 010 1.228l-2.047.865a.666.666 0 00-.354.354l-.865 2.047a.667.667 0 01-1.228 0l-.865-2.047a.666.666 0 00-.354-.354l-2.047-.865a.667.667 0 010-1.228l2.047-.865a.666.666 0 00.354-.354l.865-2.047z"*/}
                        {/*            fill="#a9b7cc"></path>*/}
                        {/*    </svg>*/}
                        {/*    <span*/}
                        {/*        className="styles_text__OlAkW styles_color--gold-primary__WVzTq styles_variant--h5___ABOK styles_bold__nhebz styles_uppercase__iz9x_">Поддержка</span>*/}
                        {/*    <div*/}
                        {/*        className="support-new">NEW*/}
                        {/*    </div>*/}
                        {/*</Link>*/}
                        {/*<ThemeSwitcher />*/}
                        {/*<button onClick={ff}>set</button>*/}
                    </div>
                    {
                        location.pathname.startsWith(PATHS.PROFILE)
                            ?
                                <>
                                    {/*<NotificationBell />*/}
                                    <DepositWallet user={user} />
                                    <div className="profile-info">
                                        <img
                                            className="avatar-profile"
                                            src={user.avatarUrl}
                                            alt="avatar"
                                        />
                                        <div className="name-profile">
                                            { user.personaName }
                                        </div>
                                    </div>
                                    <BurgerButton />
                                </>
                            :
                            user?._id ?
                                <>
                                    {/*<NotificationBell />*/}
                                    <DepositWallet user={user} />
                                    <Link className='btn profile-btn' to={PATHS.PROFILE + CHILD_PROFILE_PATHS.BASKET}>
                                        Профиль
                                    </Link>
                                    <BurgerButton />
                                </>
                                :
                                <>
                                    <a style={{"textDecoration" : "none", marginLeft: "auto"}} className='btn profile-btn' href={PATHS_API.LOGIN}>
                                        Вход
                                    </a>
                                    <BurgerButton />
                                </>
                    }
                </nav>
            </div>
        </div>
    );
};

export default Navbar;