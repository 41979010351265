import "./DepositPage.scss";
import SkinMethods from "./components/SkinMethods/SkinMethods";
import DefaultMethods from "./components/DefaultMethods/DefaultMethods";
import {useEffect, useState} from "react";
import PopupDeposit from "./components/PopupDeposit/PopupDeposit";
import PopupNotFullyIntegration from "./components/DefaultMethods/PopupNotFullyIntegration/PopupNotFullyIntegration";
import {useUserStore} from "../../../modules/shop/user/store";
import axios from "axios";
import {PATHS_API} from "../../../enum";

const DepositPage = () => {
    const user = useUserStore(state => state.user)
    const [ activePopup, setActivePopup ] = useState(false)
    const [ activePopupNotFully, setActivePopupNotFully ] = useState(false)
    const [ methodPayment, setMethodPayment ] = useState()
    const [ amountDeposit, setAmountDeposit ] = useState(100)
    const [ rateCurrency, setRateCurrency ] = useState(null);

    useEffect(() => {
        const fetchCurrentRate = async () => {
            try {
                const response = await axios.get(PATHS_API.RATE_CURRENCY);
                setRateCurrency(response.data);
            } catch (error) {
                console.error('Помилка при отриманні курсу:', error);
            }
        };

        // Вызов функции fetchCurrentRate один раз при монтировании компонента
        fetchCurrentRate();
    }, []);

    return (
        <>
            <div className="content content-none-background">
                <div className="title-deposit-wrapper">
                    <h3 className="title-deposit">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--main-text-color)" className="styles_title__icon__Xazpv">
                            <path d="M19.5 18.19c-2.481 0-4.5-1.922-4.5-4.285 0-2.363 2.019-4.286 4.5-4.286H22V7.714c0-1.05-.897-1.904-2-1.904h-1V3.905C19 2.855 18.103 2 17 2H3C1.517 2 .291 3.032.051 4.38.029 4.458 0 4.534 0 4.619v14.524C0 20.718 1.346 22 3 22h17c1.103 0 2-.854 2-1.905v-1.904h-2.5zM2 4.857c0-.525.448-.952 1-.952h14V5.81H3c-.552 0-1-.428-1-.953z"></path><path d="M23.25 11.048H19.5c-1.654 0-3 1.281-3 2.857 0 1.575 1.346 2.857 3 2.857h3.75c.414 0 .75-.32.75-.714v-4.286c0-.394-.336-.714-.75-.714zm-3.75 3.81c-.552 0-1-.428-1-.953 0-.526.448-.953 1-.953s1 .427 1 .953-.448.952-1 .952z"></path>
                        </svg>
                        <p>Пополнение баланса</p>
                    </h3>
                    <p className="title-deposit-text">
                        При ошибке оплаты попробуйте другой способ или напишите в поддержку!
                    </p>
                </div>
                <div className="skins">
                    <SkinMethods
                        setMethodPayment={setMethodPayment}
                        setActivePopup={setActivePopup}
                        setActivePopupNotFully={setActivePopupNotFully}
                    />
                </div>
                <DefaultMethods
                    setMethodPayment={setMethodPayment}
                    setActivePopup={setActivePopup}
                />
                <div className="skins-mobile">
                    <SkinMethods
                        setMethodPayment={setMethodPayment}
                        setActivePopup={setActivePopup}
                        setActivePopupNotFully={setActivePopupNotFully}
                    />
                </div>
            </div>
            <PopupDeposit
                user={user}
                setActivePopupNotFully={setActivePopupNotFully}
                activePopup={activePopup}
                setActivePopup={setActivePopup}
                methodPayment={methodPayment}
                amountDeposit={amountDeposit}
                setAmountDeposit={setAmountDeposit}
                rateCurrency={rateCurrency}
            />
            <PopupNotFullyIntegration
                user={user}
                activePopup={activePopupNotFully}
                setActivePopup={setActivePopupNotFully}
                amountDeposit={amountDeposit}
                rateCurrency={rateCurrency}
            />
        </>

    );
};

export default DepositPage;