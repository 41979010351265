import "./Products.scss";
import {IDataProduct} from "./interfaces";
import {useServersStore} from "../../../../../modules/shop/servers/store";
import {useEffect, useState} from "react";
import Product from "./components/Product/Product";
import {filterCategoryResult} from "../Categories/filterCategoryResult";

const Products = ({dataProducts, activeCategory}: IDataProduct[]) => {
    const servers = useServersStore(state=> state.servers)
    const fetchServers = useServersStore(state=> state.fetchServers)


    const filteredRandomProducts = dataProducts.filter(product => product.type === "random");

    //Фильтр по категориям
    const filterProductsCategory = filterCategoryResult(activeCategory, dataProducts);

    useEffect(() => {
        fetchServers()
    }, [])

    return (
        <div className="products">
            { filterProductsCategory.map((product, idx) => {
                return (
                    <Product key={idx} data={product} randomProducts={filteredRandomProducts} servers={servers} />
                )})
            }
        </div>
    );
};

export default Products;