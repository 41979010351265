import {useState} from "react";
import Toaster from "../../../../../shared/Toaster";
import {ToasterProps} from "../../../../../shared/Toaster/Toaster";
import {getCurrentDateInSeconds} from "../../../../../shared/functions/getRandomId";
import {a} from "vite/dist/node/types.d-aGj9QkWt";
import tf2img from "../../../../../assets/skinify_tft.webp";

export interface ISkinMethod {
    method: {
        name: string,
        style: string,
        img: string,
        createUrl: string,
        preferMethod: string,
        min: number,
        currency: string,
    },
    activeMethod: any
}

const SkinMethod = (props : ISkinMethod) => {

    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);

    return (
        <div
            // onClick={() => setToastProps({ message: "Оплата скинами в настоящее время недоступна.", type: "warning", id: getCurrentDateInSeconds() })}
            className="skin-block"
            onClick={() => props.activeMethod(props.method)}
             style={{ background: `${props.method.style}`}}
        >
            <div className="skin-card">
                <div className="skin-card-name-block d-flex just-content-space-between">
                    <div>
                        <div>Скины</div>
                        <p> { props.method.name } </p>
                    </div>
                    {/*<div className="check-method">*/}

                    {/*</div>*/}
                    <div></div>
                </div>
                <img src={props.method.img } alt={ props.method.name } />
            </div>
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </div>
    );
};

export default SkinMethod;