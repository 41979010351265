import { useEffect, useState } from 'react';
import axios from 'axios';
import "./TicketPage.scss";
import ticketSvg from "../../assets/ticket.svg";

const API_URL = 'api/tickets';

const TicketSystem = () => {
    const [tickets, setTickets] = useState([]);
    const [newTicketText, setNewTicketText] = useState("");
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [messageText, setMessageText] = useState("");

    useEffect(() => {
        fetchTickets();
    }, []);

    const fetchTickets = async () => {
        try {
            const res = await axios.get(API_URL);
            setTickets(res.data);
        } catch (error) {
            console.error("Ошибка загрузки тикетов", error);
        }
    };

    const createTicket = async () => {
        try {
            await axios.post(API_URL, { userId: "testUser", text: newTicketText });
            setNewTicketText("");
            fetchTickets();
        } catch (error) {
            console.error("Ошибка создания тикета", error);
        }
    };

    const sendMessage = async (ticketId) => {
        try {
            await axios.post(`${API_URL}/${ticketId}/messages`, { sender: "testUser", text: messageText });
            setMessageText("");
            fetchTickets();
        } catch (error) {
            console.error("Ошибка отправки сообщения", error);
        }
    };

    const closeTicket = async (ticketId) => {
        try {
            await axios.patch(`${API_URL}/${ticketId}/status`, { status: "closed" });
            fetchTickets();
        } catch (error) {
            console.error("Ошибка закрытия тикета", error);
        }
    };

    return (
        <div className="content ticket-padding">
            <div className="ticket-list-block">
                <h2 className="ticket-title">Список обращений</h2>
                <p className="ticket-subtitle"> Здесь вы можете увидеть все свои обращения за последний месяц.</p>
                <div className="ticket-list">
                    {tickets.length === 0 ? (
                        <p>Нет тикетов</p>
                    ) : (
                        <div className="space-y-2">
                            {tickets.map((ticket) => (
                                <div key={ticket._id} onClick={() => setSelectedTicket(ticket)} className="ticket-preview">
                                    {/*<img className="ticket-icon" src={ticketImg} alt="ticket" />*/}
                                    <img src={ticketSvg} alt=""/>

                                    <div className="ticket-preview-content">
                                        <p className="ticket-preview-title">
                                            Оплата
                                            <div className="status">
                                                Закрыт
                                            </div>
                                        </p>
                                        <span>Не пришли деньги</span>
                                    </div>
                                    {/*<button className="ticket-preview-btn">Посмотреть</button>*/}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {/*<div className="mb-4">*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        placeholder="Опишите проблему"*/}
                {/*        value={newTicketText}*/}
                {/*        onChange={(e) => setNewTicketText(e.target.value)}*/}
                {/*        className="border p-2 w-full mb-2"*/}
                {/*    />*/}
                {/*    <button onClick={createTicket} className="bg-blue-500 text-white px-4 py-2 rounded">*/}
                {/*        Создать тикет*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
            <div className="ticket-background-img">
                <div className="ticket-create-block">
                    <h2 className="ticket-title">Создать новый тикет...</h2>
                    <p>Вы можете создать тикет, нужно будет описать ситуацию</p>
                    <button className="ticket-create-btn">Создать тикет</button>
                </div>
            </div>



            {selectedTicket && (
                <div className="mt-4 p-4 border">
                    <h2 className="text-lg font-bold">Тикет #{selectedTicket._id}</h2>
                    <ul className="border p-2 mb-2">
                        {selectedTicket.messages.map((msg, index) => (
                            <li key={index} className="mb-1">
                                <span className="font-bold">{msg.sender}:</span> {msg.text}
                            </li>
                        ))}
                    </ul>
                    <input
                        type="text"
                        placeholder="Написать сообщение"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        className="border p-2 w-full mb-2"
                    />
                    <button onClick={() => sendMessage(selectedTicket._id)} className="bg-green-500 text-white px-4 py-2 rounded">
                        Отправить
                    </button>
                    { selectedTicket.status === "open" && (
                        <button className="ml-2 text-red-500" onClick={() => closeTicket(selectedTicket._id)}>
                            Закрыть
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default TicketSystem;


